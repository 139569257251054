import React from 'react';

const NotFound = () => {
    return (
        <div>
            <h1>404 - Not Found</h1>
            <p>Cette page n'existe pas</p>
        </div>
    );
};

export default NotFound;