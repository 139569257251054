import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

export const BasicPage = ({ title, icon }) => {
    return (
        <Container component="main" maxWidth="xs">
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center"
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>{icon}</Avatar>
                <Typography component="h1" variant="h5">
                    {title}
                </Typography>
            </Box>
        </Container>
    );
};
